@font-face {
  font-family: "iconfont"; /* Project id 2560212 */
  src: url('iconfont.woff2?t=1622080744564') format('woff2'),
       url('iconfont.woff?t=1622080744564') format('woff'),
       url('iconfont.ttf?t=1622080744564') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-guanbi:before {
  content: "\e623";
}

.icon-ziyuan14:before {
  content: "\e622";
}

.icon-ziyuan13:before {
  content: "\e61f";
}

.icon-ziyuan3:before {
  content: "\e616";
}

.icon-ziyuan5:before {
  content: "\e617";
}

.icon-ziyuan11:before {
  content: "\e618";
}

.icon-ziyuan12:before {
  content: "\e619";
}

.icon-ziyuan9:before {
  content: "\e61a";
}

.icon-ziyuan4:before {
  content: "\e61b";
}

.icon-ziyuan1:before {
  content: "\e61c";
}

.icon-ziyuan8:before {
  content: "\e61d";
}

.icon-ziyuan6:before {
  content: "\e61e";
}

.icon-ziyuan10:before {
  content: "\e620";
}

.icon-ziyuan7:before {
  content: "\e621";
}

