@import url(./assets/iconfont/iconfont.css);
* {
  -webkit-touch-callout:none;
  -moz-touch-callout:none;
  -ms-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}
html, body {
  width: 100%;
  height: 100%;
}
html, body, div, ul, li, p {
  display: block;
  margin: 0;
  padding: 0;
}
img {
  border: 0;
}
button, input {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}
input , texteare{
  -webkit-user-select:auto;
} 
canvas {
  outline: 0;
  border: 0;
}
iframe {
  border:0;
}

#root {
  width: 100%;
  height: 100%;
  font-size: 0.24rem;
  overflow: hidden;
  background: #FFFFFF;
}